<template>
  <transition name="exit-intent-fade">
    <div
      v-show="canShowExitIntent && showModal"
      class="modal-mask"
      role="dialog"
      style="z-index: 225;"
    >
      <div class="modal-wrapper">
        <div ref="exit-intent" class="modal-container modal-cta-small">
          <div style="display: none;" role="alert">
            Before you go modal
          </div>
          <button
            type="button"
            class="modal-close"
            aria-label="Close"
            @click="closeModal"
          >
            Close
          </button>
          <div class="image-top-to-bottom-right">
            <img
              v-lazy-load data-src="~/assets/images/wa-icon-shippingtruck.svg"
              alt="Truck Icon"
              role="presentation"
            />
          </div>
          <h4 v-if="header">
            {{ header }}
          </h4>
          <p>
            {{ body }}
          </p>
          <a
            v-if="button && buttonLink"
            class="button left"
            :href="buttonLink"
            @click="clickStateButtonLink"
          >
            {{ button }}
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import isMobile from 'ismobilejs'
import { pushEvent } from '~/../common/utils/user'
import { getCheckoutUrl } from '~/../common/services/checkoutServices'

const MS_PER_DAY = 86400000
export default {
  name: 'ExitIntent',
  props: {
    scrollPercentageBeforeShow: {
      type: Number,
      default: 0,
    },
    storageKey: {
      type: String,
      default: 'disableExitIntent',
    },
  },
  data() {
    return {
      scrollPercentageFlag: false,
      forbidden: true,
      showModal: false,
      isScrollingHandle: undefined,
      lastActiveTime: Date.now(),
      inactiveHandle: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'buildEnv',
      'cart',
      'toggleCart',
      'pptoLink',
      'firstPptoOrder',
      'settingsVars',
      'constance',
      'inMobileApp',
      'pptoToastModalOpen',
      'pptoEligibleOrder',
    ]),
    asYouGo() {
      return !!(this.pptoEligibleOrder && this.pptoEligibleOrder.is_as_you_go)
    },
    pptoPlaceBy() {
      if (
        !this.firstPptoOrder ||
        this.firstPptoOrder.status === 'pending_weather'
      )
        return ''
      return (
        this.$moment(this.firstPptoOrder.requested_delivery_date).format(
          'MMMM Do'
        ) + ' '
      )
    },
    checkOrderStatus() {
      if (!this.firstPptoOrder || !this.firstPptoOrder.parent_order) return ''
      return this.firstPptoOrder.parent_order
    },
    checkoutUrl() {
      return getCheckoutUrl(this.cart, this.buildEnv)
    },
    getState() {
      // There are no pop-ups for an empty cart
      if (this.cart.num_bottles > 0 && this.cart.eligible_as_ppto) {
        if (
          parseFloat(this.cart.subtotal) <
          this.settingsVars.STORE_FREE_SHIPPING_DOLLARS
        ) {
          if (
            this.$auth.loggedIn &&
            this.firstPptoOrder &&
            this.firstPptoOrder.is_as_you_go &&
            (this.firstPptoOrder.status === 'authorized_plus_avs' ||
              this.firstPptoOrder.status === 'pending_prefulfillment')
          ) {
            return 'pptoWinesYouCanAdd'
          }
          if (
            this.$auth.loggedIn &&
            this.firstPptoOrder &&
            (this.firstPptoOrder.is_as_you_go ||
              this.firstPptoOrder.status === 'pending_weather')
          ) {
            return 'pptoWinesNewOrDelayedOrder'
          }
          if (this.$auth.loggedIn && !this.firstPptoOrder) {
            return 'pptoLoggedIn'
          }
          return 'pptoAnonymous'
        }
      }
      return 'default'
    },
    stateMessages() {
      return {
        pptoLoggedIn: {
          header: "Don't want to pay for shipping?",
          body:
            "Purchase any bottle and you won't be charged for shipping at checkout. Instead, we'll hold your wine for 30 days OR until your order reaches $150.",
          button: 'Continue to checkout',
          buttonLink: this.checkoutUrl,
        },
        pptoAnonymous: {
          header: 'Shipping included on $150+',
          body:
            "Can't reach it now? Checkout and we'll hold your wine for 30 days or until your order reaches $150.",
          button: 'Continue to checkout',
          buttonLink: this.checkoutUrl,
        },
        pptoWinesYouCanAdd: {
          header: "Don't want to pay for shipping?",
          body:
            'These wines are eligible to be added to your order being delivered on ' +
            this.pptoPlaceBy +
            '.',
          button: 'Add to existing order',
          buttonLink: this.pptoLink,
        },
        pptoWinesNewOrDelayedOrder: {
          header: "Don't want to pay for shipping?",
          body: `These wines are eligible to be added to your order # ${this.checkOrderStatus}`,
          button: 'Add to existing order',
          buttonLink: this.pptoLink,
        },
        default: {
          header: '',
          body: '',
          button: '',
          buttonLink: '',
        },
      }
    },
    header() {
      return this.stateMessages[this.getState].header
    },
    body() {
      return this.stateMessages[this.getState].body
    },
    button() {
      return this.stateMessages[this.getState].button
    },
    buttonLink() {
      return this.stateMessages[this.getState].buttonLink
    },
    routeDisabled() {
      return (
        this.$route.path.includes('checkout') ||
        this.$route.path.includes('wine-club/gifting') ||
        this.$route.path.includes('giftcard')
      )
    },
    canShowExitIntent() {
      return (
        this.constance.E1_EXIT_INTENT_ENABLED === 'True' &&
        !this.inMobileApp &&
        !this.routeDisabled &&
        !this.pptoToastModalOpen &&
        this.getState !== 'default' &&
        !this.forbidden &&
        !this.toggleCart &&
        (this.asYouGo || this.cart.eligible_as_ayg)
      )
    },
    eventExtraData() {
      return {
        E1_EXIT_INTENT_ENABLED: this.constance.E1_EXIT_INTENT_ENABLED,
        E1_MIN_INITIAL_SUPPRESSION_TIME: this.constance
          .E1_MIN_INITIAL_SUPPRESSION_TIME,
        E1_MIN_NUMBER_OF_DAYS_SUPRESSION: this.constance
          .E1_MIN_NUMBER_OF_DAYS_SUPRESSION,
        E1_USER_INACTIVE_ENABLED: this.constance.E1_USER_INACTIVE_ENABLED,
        E1_USER_INACTIVE_TIME: this.constance.E1_USER_INACTIVE_TIME,
        E1_USER_INACTIVE_POLLING_PERIOD_TIME: this.constance
          .E1_USER_INACTIVE_POLLING_PERIOD_TIME,
        E1_USER_LEFT_ACTIVE_AREA_ENABLE: this.constance
          .E1_USER_LEFT_ACTIVE_AREA_ENABLE,
        E1_MOBILE_TRIGGER_SCROLL_ENABLED: this.constance
          .E1_MOBILE_TRIGGER_SCROLL_ENABLED,
        E1_MOBILE_TRIGGER_SCROLL_DISTANCE_POLLING_PERIOD_TIME: this.constance
          .E1_MOBILE_TRIGGER_SCROLL_DISTANCE_POLLING_PERIOD_TIME,
        E1_MOBILE_TRIGGER_SCROLL_DISTANCE: this.constance
          .E1_MOBILE_TRIGGER_SCROLL_DISTANCE,
        messageState: this.getState,
        messageText: this.body,
      }
    },
  },
  mounted() {
    if (!this.routeDisabled) {
      // do not load ppto order if we cannot show exit intent
      this.$store.dispatch('fetchPPTOEligibleOrder')
    }

    // If enabled, log all variables related to showing exit intent.
    this.logInitialConditionVariables()

    // Making openModal action global for use by other third parties.
    window.openExitIntentModal = this.openModal
    this.checkScrollPercentage()

    // raising the modal up is forbidden before initialDelay ms
    // on the site, and for disableDays after previously seeing popup
    setTimeout(() => {
      this.forbidden = this.localStorageDisabled()
    }, Math.trunc(parseFloat(this.constance.E1_MIN_INITIAL_SUPPRESSION_TIME) * 1000))

    if (this.inactiveHandle) {
      clearInterval(this.inactiveHandle)
      this.inactiveHandle = undefined
    }
    if (this.constance.E1_USER_INACTIVE_ENABLED === 'True') {
      this.inactiveHandle = setInterval(() => {
        if (
          Date.now() - this.lastActiveTime >
          Math.trunc(parseFloat(this.constance.E1_USER_INACTIVE_TIME) * 1000)
        ) {
          this.openModal()
        }
      }, Math.trunc(parseFloat(this.constance.E1_USER_INACTIVE_POLLING_PERIOD_TIME) * 1000))
    }

    // mobile and browser exit intent is calculated differently
    // only register event listeners that are appropriate.
    document.addEventListener('mousemove', this.onMouseMove)
    if (!this.isMobile()) {
      if (this.constance.E1_USER_LEFT_ACTIVE_AREA_ENABLE === 'True') {
        document.addEventListener('mouseout', this.mouseOutCallback)
      }
    } else if (this.constance.E1_MOBILE_TRIGGER_SCROLL_ENABLED === 'True') {
      document.addEventListener('scroll', this.onScroll)
      document.addEventListener('touchmove', this.onScroll)
    }
  },
  unmounted() {
    this.cleanEventListeners()
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.forbidden = true
      const current = new Date().getTime()
      localStorage.setItem(this.storageKey, JSON.stringify(current))
      pushEvent(
        this.$axios,
        {
          metric_name: 'Exit Intent Modal Closed',
          url_path: this.$route.fullPath,
          extra_data: this.eventExtraData,
        },
        this.$store
      )
      this.$emit('close-exit-intent')
    },
    clickStateButtonLink() {
      this.showModal = false
      pushEvent(
        this.$axios,
        {
          metric_name: 'Exit Intent Modal Link Clicked',
          url_path: this.$route.fullPath,
          extra_data: this.eventExtraData,
        },
        this.$store
      )
      this.$emit('close-exit-intent')
    },
    logInitialConditionVariables() {
      if (this.constance.E1_LOGGING === 'True') {
        /* eslint-disable no-console */
        console.log('Exit intent intial settings: \n')
        console.log({
          localStorageDisabled: this.localStorageDisabled(),
          isMobile: this.isMobile(),
          extraData: this.eventExtraData,
        })
      }
    },
    logOpenModalConditionVariables() {
      if (this.constance.E1_LOGGING === 'True') {
        /* eslint-disable no-console */
        console.log('Exit intent can show settings: \n')
        console.log({
          exitIntentEnabled: this.constance.E1_EXIT_INTENT_ENABLED === 'True',
          inMobileApp: this.inMobileApp,
          routeDisabled: this.routeDisabled,
          pptoToastModalOpen: this.pptoToastModalOpen,
          currentState: this.getState,
          forbidden: this.forbidden,
          sideCartOpen: this.toggleCart,
          aygEligible: this.asYouGo || this.cart.eligible_as_ayg,
        })
      }
    },
    openModal() {
      this.logOpenModalConditionVariables()
      // If we cannot open exit intent then we should abandoned further
      // attempts, this gives the application the feel that it is "waiting"
      // to flash the exit intent screen even though they are engaged.
      if (!this.canShowExitIntent) {
        if (this.forbidden && this.localStorageDisabled()) {
          this.cleanEventListeners()
        }
        return
      }
      if (!this.showModal) {
        pushEvent(
          this.$axios,
          {
            metric_name: 'Exit Intent Modal Opened',
            url_path: this.$route.fullPath,
            extra_data: this.eventExtraData,
          },
          this.$store
        )
      }
      this.showModal = true
      this.$emit('show-exit-intent')
      this.cleanEventListeners()
    },
    onScroll() {
      this.lastActiveTime = Date.now()
      if (!this.scrollPercentageFlag) this.checkScrollPercentage()
      this.scrollCallback()
    },
    onMouseMove() {
      this.lastActiveTime = Date.now()
    },
    localStorageDisabled() {
      // Localstorage may not be the correct solution, and expiring cookie could make
      // more sense. But this works.
      if (localStorage.getItem(this.storageKey)) {
        const old = JSON.parse(localStorage.getItem(this.storageKey))
        const current = new Date().getTime()

        return !(
          current - old >
          parseInt(this.constance.E1_MIN_NUMBER_OF_DAYS_SUPRESSION) * MS_PER_DAY
        )
      } else return false
    },
    isMobile() {
      const { req: request } = this.$nuxt.context
      return isMobile(
        request ? request.headers['user-agent'] : window.navigator
      ).phone
    },
    checkScrollPercentage() {
      // polls the current percentage of scroll for the document, user
      // scrolling down enables the 'trigger' for raising the modal if a
      // fast scroll up is subsequently detected.
      const stKey = 'scrollTop'
      const shKey = 'scrollHeight'
      if (this.scrollPercentageBeforeShow === 0) {
        this.scrollPercentageFlag = true
        return
      }
      const docElement = document.documentElement
      const docBody = document.body
      const percentage =
        ((docElement[stKey] || docBody[stKey]) /
          ((docElement[shKey] || docBody[shKey]) - docElement.clientHeight)) *
        100
      this.scrollPercentageFlag = this.scrollPercentageBeforeShow <= percentage
    },
    mouseOutCallback(evt) {
      // for fullsized browsers, detects when a user's mouse has left the active portion
      // of the browser.
      if (
        evt.toElement === null &&
        evt.relatedTarget === null &&
        this.scrollPercentageFlag
      )
        this.openModal()
    },
    scrollCallback() {
      if (this.isScrollingHandle) return

      const startPos = window.scrollY
      this.isScrollingHandle = setTimeout(() => {
        this.isScrollingHandle = undefined
        const velUp = window.scrollY - startPos
        if (
          velUp <
            parseInt(this.constance.E1_MOBILE_TRIGGER_SCROLL_DISTANCE) * -1 &&
          this.scrollPercentageFlag
        )
          this.openModal()
      }, parseFloat(this.constance.E1_MOBILE_TRIGGER_SCROLL_DISTANCE_POLLING_PERIOD_TIME) * 1000)
    },
    cleanEventListeners() {
      document.removeEventListener('mousemove', this.onMouseMove)
      if (!this.isMobile()) {
        if (this.constance.E1_USER_LEFT_ACTIVE_AREA_ENABLE === 'True') {
          document.removeEventListener('mouseout', this.mouseOutCallback)
        }
      } else if (this.constance.E1_MOBILE_TRIGGER_SCROLL_ENABLED === 'True') {
        document.removeEventListener('scroll', this.onScroll)
        document.removeEventListener('touchmove', this.onScroll)
      }
      if (this.inactiveHandle) {
        clearInterval(this.inactiveHandle)
        this.inactiveHandle = undefined
      }
    },
  },
}
</script>
